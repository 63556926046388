import React, {useEffect, useState} from 'react';
import {
    Box,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel, IconButton,
    MenuItem,
    SelectChangeEvent
} from "@mui/material";
import styles from './main-selection-calc.module.scss';
import {TextInput} from "shared/ui/text-input";
import {CheckboxInput} from "shared/ui/checkbox-input";
import {SelectInput} from "shared/ui/select-input";
import {SliderInput} from "shared/ui/slider-input";
import {FileInputCustom} from "shared/ui/file-input-custom";
import {ButtonCustom} from "shared/ui/button-custom";
import {useNavigate} from "react-router-dom";
import CloseIcon from 'shared/assets/icons/close.svg'
import {apiClient, getUser} from "shared/api";
import {PhoneInput} from "shared/ui/phone-input";
import {MuiTelInputInfo} from "mui-tel-input";
import {ValidationRegular} from 'shared/model/constants/validations'
import clsx from "clsx";


interface SelectionCalcProps {
    inDashoard?: boolean;
    title: string;
    typeCars: 'cars' | 'commercial' | 'Спецтехника' | 'Грузовые' | 'trailers' | 'main' | string;
}

export const MainSelectionCalc = (props: SelectionCalcProps) => {
    const {title, typeCars, inDashoard} = props
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDesc, setModalDesc] = useState('');
    const [user, setUser] = useState<any>();
    const [titleModalBtn, setTitleModalBtn] = useState<string>('Войти в личный кабинет');
    const [url, setUrl] = useState<string>('/auth');

    const handleClose = () => {
        setOpen(false);
    };

    const [inn, setInn] = useState<string | number>('');
    const [bio, setBio] = useState<string | number>('');
    const [email, setEmail] = useState<string | number>('');
    const [phone, setPhone] = useState<string | undefined>('');
    const [vehicleType, setVehicleType] = useState<string | number>('');

    const [agentInn, setAgentInn] = useState<string | number>('');
    const [agentBio, setAgentBio] = useState<string | number>('');
    const [agentEmail, setAgentEmail] = useState<string | number>('');
    const [agentPhone, setAgentPhone] = useState<string | number>('');

    const [quantity, setQuantity] = useState<string | number>('');
    const [isAgent, setIsAgent] = useState<boolean>();

    const [bioErr, setBioErr] = useState(false)
    const [innErr, setInnErr] = useState(false)
    const [conditionErr, setConditionErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [phoneErr, setPhoneErr] = useState(false)
    const [quantityErr, setQuantityErr] = useState(false)
    const [car_typeErr, setCar_typeErr] = useState(false)
    const [car_brandErr, setCar_brandErr] = useState(false)
    const [car_modelErr, setCar_modelErr] = useState(false)
    const [validation, setValidation] = useState(false)

    const handleChangeInn = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInn(value || '');
        const valid_inn: boolean = !(ValidationRegular.INN.test(value?.toString()))

        setInnErr(false)
    }
    const handleChangeBio = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setBio(value);
        const bio_valid: boolean = !(value?.toString().length
          && ValidationRegular.BIO.test(value?.toString()))

        setBioErr(false)
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value);
        const email_valid: boolean = !(value?.toString().length
          && ValidationRegular.MAIL.test(value?.toString()))

        setEmailErr(false)
    }
    const handleChangePhone = (value: string, info: MuiTelInputInfo) => {
        setPhone(value);
        setPhoneErr(!value)
    }
    const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuantity(value);
        const quantity_valid: boolean = !(value?.toString().length
          && ValidationRegular.QUANTITY.test(value?.toString()))

        setQuantityErr(false)
    }

    const [paymentVariantErr, setPaymentVariantErr] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleClickOpen = async () => {
        const formData = new FormData();

        const budgetInput = document.querySelector<HTMLInputElement>('[name="budget"]');

        let carParamsArray = []

        if (typeCars != 'main') {
            carParamsArray = Object.entries(selectedValues).map(([key, value]) => ({
                fieldName: key,
                fieldValue: value,
            }));

            formData.append('car_params', JSON.stringify(carParamsArray));
        } else if (typeCars == 'main' && inDashoard) {
            carParamsArray = Object.entries(selectedValues).map(([key, value]) => ({
                fieldName: key,
                fieldValue: value,
            }));

            formData.append('car_params', JSON.stringify(carParamsArray));
        } else {
            formData.append('car_params', JSON.stringify(vehicleParamsMainList[0]?.car_params));
        }
        if (inDashoard) {
            if (isAgent) {
                formData.append('agent_bio', agentBio?.toString());
                formData.append('agent_email', agentEmail?.toString());
                formData.append('agent_phone', agentPhone?.toString());
                formData.append('agent_inn', agentInn?.toString());

            }
        }



        if (email?.toString().length && ValidationRegular.MAIL.test(email?.toString())) {
            formData.append('email', email?.toString() || '')
        } else {
            setEmailErr(true)
        }

        if (phone?.toString().length) {
            formData.append('phone', phone?.toString() || '')
        } else {
            setPhoneErr(true)
        }
        if (bio?.toString().length && ValidationRegular.BIO.test(bio?.toString())) {
            formData.append('bio', bio?.toString() || '')
        } else {
            setBioErr(true)
        }
        //if (inn?.toString().length) {
        //    formData.append('inn', inn?.toString()  || '')
        // }
        // else {
        //     setInnErr(true)
        // }
        if (inn?.toString().length) {
            if (ValidationRegular.INN.test(inn?.toString()) || inn == '' ) {
                formData.append('inn', inn?.toString()  || '')
            } else {
                setInnErr(true)
            }
        }

        if (quantity?.toString().length && ValidationRegular.QUANTITY.test(quantity?.toString())) {
            formData.append('quantity', quantity?.toString() || '')
        } else {
            setQuantityErr(true)
        }



        const conditions = Array.from(document.querySelectorAll<HTMLInputElement>('[name^="condition-"]:checked'))
            .map((input) => input.labels ? input.labels[0]?.innerText : '')
            .filter((text) => text)
            .join(', ');



        if (conditions) {
            formData.append('condition', conditions);
            setConditionErr(false)
        } else {
            setConditionErr(true)
        }

        formData.append('type', 'select');

        if (budgetInput) formData.append('budget', budgetInput.value);

        if (paymentV.length > 2) {
            formData.append('pay_method', paymentV);
        } else {
            setPaymentVariantErr(true)
        }


        if (typeCars != 'main' || inDashoard) {
            if (vehicleType.toString()) {
                formData.append('car_type', vehicleType.toString());
            }
        } else {
            if (car_type.toString()) {
                formData.append('car_type', car_type.toString());
            } else {
                setCar_typeErr(true)
            }
        }




        if (typeCars != 'main' || inDashoard) {
            if (car_subtype.toString()) {
                formData.append('car_subtype', car_subtype.toString());
            }
        } else {
            formData.append('car_subtype', subtypeMain.toString());
        }

        if (brand.toString()) {
            formData.append('car_brand', brand.toString());
        } else {
            setCar_brandErr(true)
        }

        if (model.toString()) {
            formData.append('vehicle', model.toString());
        } else {
            setCar_modelErr(true)
        }

        if (kp && kp[0]) {
            formData.append('kp', kp[0]);
        } else {
            console.log('Файл отсутствует');
        }

        if (typeCars == "main" && !inDashoard) {
            setValidation(!!(
              !bioErr
              && !emailErr
              && !phoneErr
              && !quantityErr
              && (inn.toString().length == 0 || inn.toString().length == 10 || inn.toString().length == 12)
              && !paymentVariantErr
              && paymentV.length
              && quantity
              && !car_typeErr
              && !car_brandErr
              && !car_modelErr))
        } else {
            setValidation(!!(
              !bioErr
              && !emailErr
              && !phoneErr
              && !quantityErr
              && (inn.toString().length == 0 || inn.toString().length == 10 || inn.toString().length == 12)
              && !paymentVariantErr
              && paymentV.length
              && carParamsArray.length
              && quantity
              && !car_typeErr
              && !car_brandErr
              && !car_modelErr))
        }

        if (validation){

            try {
                setLoading(true)

                const url = inDashoard ? '/select-applications/new' : '/new-user-app'

                const response = await apiClient.post(url, formData);

                if (inDashoard) {
                    navigate(-1);
                } else {
                    setModalTitle('Благодарим за оформление заявки!');
                    setModalDesc(
                      'На Ваш E-mail отправлено письмо, содержащее в себе логин и пароль для личного кабинета. В нем будут храниться все ваши заявки.'
                    );
                    setTitleModalBtn('Войти в личный кабинет');
                    setUrl('/auth');
                    setOpen(true);
                }
            } catch (error:any) {
                if (error.response) {
                    const errorMessage = error.response.data?.error?.message;

                    if (errorMessage === 'User already exists') {
                        setModalTitle('Ошибка! Вы уже зарегистрированы!');
                        setModalDesc(
                          'Создать новую или узнать статус текущих заявок можно в личном кабинете TLS Group.'
                        );
                        setTitleModalBtn('Войти в личный кабинет');
                        setUrl('/auth');
                    } else if (errorMessage === 'Missing required fields') {
                        setModalTitle('Ошибка! Проверьте заполнение обязательных полей!');
                        setModalDesc(
                          'Для оформления заявки должны быть заполнены все обязательные поля!'
                        );
                        setTitleModalBtn('Продолжить');
                        setUrl('');
                    } else {
                        setModalTitle('Ошибка!');
                        setModalDesc('Произошла непредвиденная ошибка. Попробуйте снова позже.');
                        setTitleModalBtn('Продолжить');
                        setUrl('');
                    }
                } else {
                    setModalTitle('Ошибка!');
                    setModalDesc('Произошла непредвиденная ошибка. Попробуйте снова позже.');
                    setTitleModalBtn('Продолжить');
                    setUrl('');
                }
                setOpen(true);
            } finally {
                setLoading(false);
            }


        }
    };

    let navigate = useNavigate()

    const marks = [
        {
            value: 1000000,
            label: '1 млн',
        },
        {
            value: 25500000,
            label: '25.5 млн',
        },
        {
            value: 50000000,
            label: '50 млн',
        },
    ];

    const [brandValueList, setBrandValueList] = useState([]);
    const [typeValueList, setTypeValueList] = useState([]);
    const [subTypeValueList, setSubTypeValueList] = useState([]);
    const [modelValueList, setModelValueList] = useState([]);
    const [vehicleParamsList, setVehicleParamsList] = useState([]);
    const [vehicleParamsMainList, setVehicleParamsMainList] = useState<any>([]);

    const [kp, setKp] = useState<any>();

    const getIdByName = (name: string, items: any) => {
        const item = items.find((item: any) => item.name === name);
        return item ? item.id : null;
    };
    const getNameById = (id: number | string, items: any) => {
        const item = items.find((item: any) => item.id === id);
        return item ? item.name : null;
    };

    const userGetInfo = async ()=> {
        if (inDashoard) {
            const userInfo = await getUser();
            setUser(userInfo);
            setIsAgent(userInfo.agent);
            if (userInfo.agent) {
                setAgentBio(userInfo.bio)
                setAgentEmail(userInfo.email)
                setAgentPhone(userInfo.phone)
                setAgentInn(userInfo.inn)
            } else {
                setBio(userInfo?.bio)
                setEmail(userInfo?.email)
                setPhone(userInfo?.phone)
                setInn(userInfo?.inn)
            }
        }
    }

    useEffect(() => {
        if(inDashoard) {
            userGetInfo()
        }
    }, []);


    useEffect(() => {
        fetchData();
    }, [typeCars]);

    const fetchData = async () => {
        try {
            const response1 = await apiClient.get('/car-types/all');
            const data1 = await response1;
            setTypeValueList(data1.data.data);

            if (typeCars != 'main' && !inDashoard) {
                const typeId = getIdByName(typeCars, data1.data.data);
                if (typeId) {
                    const response2 = await apiClient.post('/car-subtypes/subtypes', {
                          typeId: typeId})
                    const data2 = await response2;
                    setSubTypeValueList(data2.data.data);
                }
            }

        } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
        }
    };

    const getBrandsForType = async (car_type: number | string) => {
        const response = await apiClient.post('/car-brands/type', {typeId: car_type
        })
        const data = await response;
        setBrandValueList(data.data.data);
    }

    const getSubtypeForType = async (car_type: number | string) => {
        const response = await apiClient.post('/car-subtypes/subtypes', {
            typeId: car_type})
        const data = await response;
        setSubTypeValueList(data.data.data);
    }

    const getBrandsForSubType = async (car_subtype: number | string) => {
        const response = await apiClient.post('/car-brands/subtype', {subtypeId: car_subtype})
        const data = await response;
        setBrandValueList(data.data.data)
    }

    const getVehicleForBrand = async (brand: number | string, type: number | string) => {
        const response = await apiClient.post('/vehicles/brandType', {brandId: brand, typeId: type})
        const data = await response;
        setModelValueList(data.data.data)

    }

    const getVehicleForBrandAndSubType = async (brand: number | string, subtype: number | string) => {
        const response = await apiClient.post('/vehicles/brandSubType',{brandId: brand, subtypeId: subtype})
        const data = await response;
        setModelValueList(data.data.data)
    }

    const getVehicleParams = async (model: number | string) => {
        const response = await apiClient.post('/vehicles/car_params', {modelId: model})
        const data = await response;
        setVehicleParamsList(data.data.data)
        setVehicleParamsMainList(data.data.data)
    }

    const getVehicleSubtype = async (model: number | string) => {
        const response = await apiClient.post('/vehicles/subtype', {modelId: model})
        const data = await response;
        setSubtypeMain(data.data.data)
    }

    const getVehicleType = async (model: number | string) => {
        const response = await apiClient.post('/vehicles/give-type', {modelId: model})
        const data = await response;
        setVehicleType(data.data.data)
    }

    const [car_type, setCar_type] = useState<number | string>('');
    const [car_subtype, setCar_subtype] = useState<number | string>('');
    const [subtypeMain, setSubtypeMain] = useState<number | string>('');
    const [brand, setBrand] = useState<number | string>('');
    const [model, setModel] = useState<number | string>('');


    const [paymentV, setPayment] = useState<string>("");
    const [typeCarTruck, setTypeCarTruck] = useState<string>("");
    const [typeCarSpecial, setTypeCarSpecial] = useState<string>("");

    const handleChangeCar_type = (event: SelectChangeEvent<unknown>) => {
        if (inDashoard) {
            setCar_type(event.target.value as number);
            getBrandsForType(event.target.value as number);
            getSubtypeForType(event.target.value as number)
            setBrand('');
            setModel('');
            setCar_subtype('')
            setCar_typeErr(false);
        } else {
            setCar_type(event.target.value as number);
            getBrandsForType(event.target.value as number);
            setBrand('');
            setModel('');
            setCar_typeErr(false);
        }
    };
    const handleChangeCar_subtype = (event: SelectChangeEvent<unknown>) => {
        setCar_subtype(event.target.value as number);
        getBrandsForSubType(event.target.value as number);
        setBrand('');
        setModel('');
    };
    const handleChangeBrand = (event: SelectChangeEvent<unknown>) => {
        setBrand(event.target.value as number);
        if (typeCars == 'main' && !inDashoard) {
            getVehicleForBrand(event.target.value as number, car_type as number);
        } else {
            getVehicleForBrandAndSubType(event.target.value as number, car_subtype as number);
        }

        setModel('');
        setCar_brandErr(false)
    };
    const handleChangeModel = (event: SelectChangeEvent<unknown>) => {
        setModel(event.target.value as number);
        getVehicleParams(event.target.value as number);
        getVehicleSubtype(event.target.value as number);
        getVehicleType(event.target.value as number);
        resetAllValues()
        setCar_modelErr(false)
    };


    const handleChangePayment = (event: SelectChangeEvent<unknown>) => {
        setPayment(event.target.value as string);
        setPaymentVariantErr(false)
    };
    const handleChangeKp = (event: any) => {
        setKp(event.target.files);
    };

    const typeCarsSelectBlock = () => {
        if (typeCars == 'main' && !inDashoard) {
            return (
                <Box className={`${styles.ts} ${styles.small}`}>
                    <SelectInput
                        name={'car_type'}
                        value={car_type}
                        required
                        error={car_typeErr}
                        onChange={handleChangeCar_type}
                        placeholder={'Выберите ТС'}
                    >
                        {
                            typeValueList?.map((item: { id: number, name: string, documentId: string }) => (
                                (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                            ))
                        }
                    </SelectInput>
                </Box>
            )
        }
        if (typeCars == 'main' && inDashoard) {
            return (
              <>
                  <Box className={`${styles.ts} ${styles.small}`}>
                      <SelectInput
                        name={'car_type'}
                        value={car_type}
                        required
                        error={car_typeErr}
                        onChange={handleChangeCar_type}
                        placeholder={'Выберите ТС'}
                      >
                          {
                              typeValueList?.map((item: { id: number, name: string, documentId: string }) => (
                                (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                              ))
                          }
                      </SelectInput>
                  </Box>
                  <Box className={`${styles.ts} ${styles.small}`}>
                      <SelectInput
                        value={car_subtype}
                        name={'car_subtype'}
                        onChange={handleChangeCar_subtype}
                        placeholder={'Выберите тип ТС'}
                      >
                          {subTypeValueList.map((item: { id: number, name: string, documentId: string }) =>
                            (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                          )}
                      </SelectInput>
                  </Box>
              </>
            )
        }
        if (typeCars !== 'main' && !inDashoard) {
            return (
                <Box className={`${styles.ts} ${styles.small}`}>
                    <SelectInput
                        value={car_subtype}
                        name={'car_subtype'}
                        onChange={handleChangeCar_subtype}
                        placeholder={'Выберите тип ТС'}
                    >
                        {subTypeValueList.map((item: { id: number, name: string, documentId: string }) =>
                            (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                        )}
                    </SelectInput>
                </Box>
            )
        }
    }

    const conditionBrandDisable = () => {
        let condition: boolean;
        if (typeCars == 'main' && !car_type) {
            condition = true;
        } else if (typeCars == 'trucks' && typeCarTruck == "") {
            condition = true;
        } else if (typeCars == 'special' && typeCarSpecial == "") {
            condition = true;
        } else if (typeCars == 'main' && car_type == "") {
            condition = true;
        } {
            condition = false;
        }
        return condition;
    }

    const car_paramsTemplate = (car_subtypeName:string) => {
        if (car_subtypeName == 'Бульдозер') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Рабочий вес, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Модель двигателя'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя л.с.'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Автокран') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Грузоподъемность, т'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Длина стрелы, м'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Макс. грузовой момент kH x m'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Асфальтоукладчик') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Производительность т/ч'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Ширина укладки, мм'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Автогрейдер') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Масса, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Модель двигателя'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Каток') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Рабочая масса, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность, кВт'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Колесный экскаватор') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Номинальная мощность, кВт'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Объем ковша, м3'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Эксплуатационная масса, кг'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Мини-экскаватор') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Общий вес, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя, kW'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Объем ковша, м3'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Фронтальный погрузчик') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Рабочий вес, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Объем ковша, м3'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Грузоподъемность, кг'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Гусеничный экскаватор') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Номинальная мощность, кВт'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Объем ковша, м3'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Эксплуатационная масса, кг'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Карьерный самосвал') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Грузоподъемность, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя, кВт'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Дорожная фреза') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя, л.с.'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Ширина фрезерования, мм'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Глубина фрезерования, мм'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Экскаватор-погрузчик') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Эксплуатационная масса, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя, л.с.'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Объем ковша, м3'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Мини-погрузчик') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Эксплуатационная масса, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Грузоподъемность, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Объем ковша, м3'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Шоссейный самосвал') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Грузоподъемность, кг'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя л.с.'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Объем кузова, м3'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Тягач магистральный') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя, л.с.'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Скорость движения, км/ч'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Коробка передач'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Колесная формула'}
                        />
                    </Box>
                </>
            )
        }
        if (car_subtypeName == 'Автобетоносмеситель') {
            return(
                <>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Объем цистерны, м3'}
                        />
                    </Box>
                    <Box className={`${styles.brand} ${styles.small}`}>
                        <SelectInput
                            disabled
                            placeholder={'Мощность двигателя л.с.'}
                        />
                    </Box>
                </>
            )
        }
    }

    const [selectedValues, setSelectedValues] = useState<{ [key: string]: string | number }>({});

    const resetAllValues = () => {
        setSelectedValues((prevState) => {
            const resetState: { [key: string]: string } = {};
            for (const key in prevState) {
                resetState[key] = "";
            }
            return resetState;
        });
    };

    const handleChange = (fieldName: string, value: string | number) => {
        setSelectedValues((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));
    };

    const car_params = (model:string | number) => {
        type paramType = {
            fieldName: string;
            fieldValue: string | number;
        }


        type vehicleModelType = {
            id: number;
            car_params: paramType[];
        };

        const vehicleModelList: vehicleModelType[] = modelValueList;

        const vehicleModel = vehicleModelList.find((item) => item.id === model);

        return (
            <>
                {vehicleModel?.car_params.map((item: { fieldName: string, fieldValue: string | number }) =>
                    (
                    <Box key={item.fieldName} className={`${styles.payment} ${styles.small}`}>
                        <SelectInput
                            value={selectedValues[item.fieldName] || ""}
                            onChange={(event) => handleChange(item.fieldName, event.target.value as number)}
                            placeholder={item.fieldName}

                        >
                            <MenuItem key={item.fieldName} value={item.fieldValue}>
                                {item.fieldValue}
                            </MenuItem>
                    </SelectInput>
                    </Box>
                    )
                )}
            </>
        )
    }

    return (
        <Box component="form" className={styles.calcContainer}>
            <Box className={styles.title}>{title}</Box>
            {(!inDashoard || (inDashoard && isAgent)) && (
              <>
                <Box className={`${styles.bio} ${styles.big}`}>
                    <TextInput onChange={handleChangeBio} value={bio || ''} error={bioErr} name={'bio'} type={'text'}
                               placeholder={'Введите ФИО'} required/>
                </Box>
                <Box className={`${styles.email} ${styles.small}`}>
                    <TextInput onChange={handleChangeEmail} value={email || ''} error={emailErr} name={'email'} type={'email'} required
                               placeholder={'Введите E-mail'}/>
                </Box>
                <Box className={`${styles.tel} ${styles.small}`}>
                    <PhoneInput onChange={handleChangePhone} value={phone || ''} error={phoneErr} name={'phone'} required
                                placeholder={'Введите телефон'}/>
                </Box>
             </>
                )
            }
            <Box className={`${styles.condition} ${styles.small}`}>
                <FormControlLabel
                    sx={{
                        margin: 0,
                        '.MuiFormControlLabel-label': {fontSize: '16px', fontWeight: 500, letterSpacing: '0.02em'}
                    }}
                    control={
                    <CheckboxInput
                        defaultChecked={true}
                        name={'condition-new'}
                        className={clsx([conditionErr && `${styles.condition_err}`])}
                    />}
                    label="Новый"
                />
                <FormControlLabel
                    sx={{
                        margin: 0,
                        '.MuiFormControlLabel-label': {fontSize: '16px', fontWeight: 500, letterSpacing: '0.02em'}
                    }}
                    control={
                    <CheckboxInput
                        name={'condition-old'}
                        className={clsx([conditionErr && `${styles.condition_err}`])}
                    />}
                    label="С пробегом"
                />
            </Box>

            <Box className={`${styles.inn} ${styles.small}`}>
                <TextInput name={'inn'} type={'number'} error={innErr} onChange={handleChangeInn} value={inn || ''} placeholder={'Введите ИНН'}/>
            </Box>

            <Box className={`${styles.payment} ${styles.big}`}>
                <SelectInput
                    name={'paymentVariant'}
                    value={paymentV}
                    onChange={handleChangePayment}
                    required
                    placeholder={'Выберите способ покупки'}
                    error={paymentVariantErr}
                >
                    <MenuItem value='Собственные средства'>Собственные средства</MenuItem>
                    <MenuItem value='Лизинг'>Лизинг</MenuItem>
                </SelectInput>
            </Box>
            <Box className={`${styles.quantity} ${styles.small}`}>
                <TextInput onChange={handleChangeQuantity} value={quantity || ''} error={quantityErr} required name={'quantity'}
                           type={'number'} placeholder={'Введите кол-во ТС'}/>
            </Box>

            {typeCarsSelectBlock()}

            <Box className={`${styles.brand} ${styles.small}`}>
                <SelectInput
                    disabled={conditionBrandDisable()}
                    value={brand}
                    required
                    error={car_brandErr}
                    onChange={handleChangeBrand}
                    placeholder={'Выберите марку'}
                >
                    {brandValueList.map((item: { id: number, name: string, documentId: string }) =>
                        (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                    )}
                </SelectInput>
            </Box>
            <Box className={`${styles.payment} ${styles.small}`}>
                <SelectInput
                    disabled={!brand}
                    value={model}
                    required
                    error={car_modelErr}
                    onChange={handleChangeModel}
                    placeholder={'Выберите модель'}
                >
                    {
                        modelValueList.map((item: { id: number, name: string, documentId: string }) => (
                            (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                        ))
                    }
                </SelectInput>
            </Box>

            {model && (typeCars != 'main' || inDashoard) ? car_params(model) : car_paramsTemplate(getNameById(car_subtype, subTypeValueList))}



            <Box className={`${styles.slider} ${styles.big}`}>
                <SliderInput
                    title={'Выберите располагаемый бюджет на покупку, ₽'}
                    marks={marks}
                    name="budget"
                    step={100000}
                    defaultValue={5000000}
                    min={1000000}
                    symbol={'₽'}
                    max={50000000}
                />
            </Box>
            <Box className={`${styles.file} ${styles.big}`}>
                <FileInputCustom value={kp} onChange={handleChangeKp}
                                 text={'Прикрепить файл с имеющимся предложением'}/>
            </Box>
            <Box className={`${styles.submitBtn} ${styles.big}`}>
                <ButtonCustom onClick={handleClickOpen}>
                    {loading ? 'Подождите...' : 'Оформить заявку на подбор'}
                </ButtonCustom>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    '.MuiDialog-paper': {
                        padding: '30px 30px 50px',
                        borderRadius: '10px',
                        maxWidth: '503px'
                    }
                }}
            >
                <DialogTitle className={styles.modalTitle} id="alert-dialog-title">
                    <span>{modalTitle}</span>
                    <IconButton
                        disableRipple
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 12,
                            top: 12,
                            fontSize: 18
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={styles.modalDescription} id="alert-dialog-description">
                        <span>{modalDesc}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles.modalButtonWrapper}>
                    {!inDashoard && (
                      <ButtonCustom className={styles.modalButton} onClick={() => {navigate(`${url}`); setOpen(false)}}>
                          {titleModalBtn}
                      </ButtonCustom>
                    )}
                </DialogActions>
            </Dialog>

        </Box>
    );
};
