import React, {useState} from 'react';
import styles from './agent-register.module.scss';
import {Box, FormControlLabel} from "@mui/material";
import {CheckboxInput} from "shared/ui/checkbox-input";
import {ButtonCustom} from "shared/ui/button-custom";
import {TextInput} from "shared/ui/text-input";
import {useNavigate} from "react-router-dom";
import {apiClient} from "shared/api";
import {PhoneInput} from "shared/ui/phone-input";
import {MuiTelInputInfo} from "mui-tel-input";
import {ValidationRegular} from "shared/model/constants/validations";
import clsx from "clsx";

export const AgentRegister = () => {
  const [goodReg, setGoodReg] = useState<boolean>(false)
  let navigate = useNavigate();

  const rules = (event:any) => {
    event?.preventDefault()
    const link = document.createElement("a");
    link.href = "/Политика_в_отношении_обработки_персональных_данных.pdf";
    link.download = "Политика в отношении обработки персональных данных.pdf";
    link.click();
  }

  const [check, setCheck] = useState(false)
  const [check_err, setCheck_Err] = useState(false)
  const [inn, setInn] = useState('')
  const [bio, setBio] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [pass_repeat, setPass_repeat] = useState('')

  const [bioErr, setBioErr] = useState(false)
  const [innErr, setInnErr] = useState(false)
  const [phoneErr, setPhoneErr] = useState(false)
  const [emailErr, setEmailErr] = useState(false)
  const [passErr, setPassErr] = useState(false)
  const [pass_repeatErr, setPass_repeatErr] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleChangeInn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInn(value || '');
    const valid_inn: boolean = !(ValidationRegular.INN.test(value?.toString()))

    //setInnErr(valid_inn)
    setInnErr(false)
  }
  const handleChangeBio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setBio(value);
    const bio_valid: boolean = !(value?.toString().length
      && ValidationRegular.BIO.test(value?.toString()))

    setBioErr(false)
  }
  const handleChangePhone = (value: string, info: MuiTelInputInfo) => {
    setPhone(value);
    setPhoneErr(!value)
  }
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    const email_valid: boolean = !(value?.toString().length
      && ValidationRegular.MAIL.test(value?.toString()))

    setEmailErr(false)
  }
  const handleChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPass(event.target.value);
    setPassErr(false)
  }
  const handleChangePass_repeat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPass_repeat(event.target.value);
    setPass_repeatErr(false)
  }
  const handlePastePass_repeat = (e: any) => {
    e.preventDefault();
  };
  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheck(e.target.checked);
    e.target.checked ? setCheck_Err(false) : setCheck_Err(true) ;

  };

  const handleSubmit = async () => {
    setCheck_Err(!check);

    let formData = new FormData;


    if (bio?.toString().length && ValidationRegular.BIO.test(bio?.toString())) {
      formData.append('bio', bio)
    } else {
      setBioErr(true)
    }

    if (phone?.toString().length) {
      formData.append('phone', phone)
    } else {
      setPhoneErr(true)
    }

    if (email?.toString().length && ValidationRegular.MAIL.test(email?.toString())) {
      formData.append('email', email)
    } else {
      setEmailErr(true)
    }

    if (pass.toString().length && pass_repeat.toString().length && (pass == pass_repeat)) {
      formData.append('password', pass)
    } else {
      setPassErr(true);
      setPass_repeatErr(true)
    }

    if (inn?.toString().length) {
      if (ValidationRegular.INN.test(inn?.toString()) || inn == '' ) {
        formData.append('inn', inn?.toString()  || '')
      } else {
        setInnErr(true)
      }
    }

    if (!bioErr && !phoneErr && !emailErr && check && !passErr && !pass_repeatErr && (pass == pass_repeat) ) {
      try {
        setLoading(true)
        const response = await apiClient.post('/new-agent', formData);
        setLoading(false);
        setGoodReg(true);
      } catch (error: any) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {

    }
  };

  let renderBlock = () => {
    if (goodReg) {
      return (<Box className={`${styles.goodRegInfo}, ${styles.loginPage}`}>
        <Box className={styles.loginTitleBlock}>
          <h2 className={styles.loginTitle}>
            <span className={styles.loginTitleBlack}>Благодарим за регистрацию!</span>
          </h2>
          <span className={styles.loginDesc}>Наши менеджеры в ближайшее время подтвердят вашу учетную запись в личном кабинете TLS Group. Уведомление об успешной верификации придет на e-mail, указанный при регистрации.</span>
        </Box>
        <Box className={styles.btnBlock}>
          <Box className={styles.submitBtn}>
            <ButtonCustom onClick={() => navigate('/auth/')}>
              Перейти на окно входа в личный кабинет
            </ButtonCustom>
          </Box>
          <span className={styles.spanBtnBlock}>или</span>
          <Box className={`${styles.submitBtn} ${styles.blackBtn}`}>
            <ButtonCustom onClick={() => navigate('/')}>
              Вернуться на сайт
            </ButtonCustom>
          </Box>
        </Box>
      </Box>)
    } else {
      return (<Box className={styles.loginPage}>
        <Box className={styles.loginTitleBlock}>
          <h2 className={styles.loginTitle}>
            <span className={styles.loginTitleBlack}>Введите ваши данные для регистрации в личном кабинете</span>
          </h2>
        </Box>
        <Box className={styles.loginForm}>
          <Box className={styles.agentRegForm}>
            <Box className={styles.inputWrapper}>
              <span className={styles.inputLabel}>ФИО</span>
              <TextInput
                sx={{'.MuiOutlinedInput-notchedOutline': {border: '1px solid #B5D4F9'}}}
                name={'bio'} value={bio} onChange={handleChangeBio} error={bioErr} type={'text'} placeholder={'ФИО'}/>
            </Box>
            <Box className={styles.inputWrapper}>
              <span className={styles.inputLabel}>ИНН</span>
              <TextInput
                sx={{'.MuiOutlinedInput-notchedOutline': {border: '1px solid #B5D4F9'}}}
                name={'inn'} value={inn} error={innErr} onChange={handleChangeInn} type={"number"} placeholder={'ИНН'}/>
            </Box>
            <Box className={styles.inputWrapper}>
              <span className={styles.inputLabel}>E-mail</span>
              <TextInput
                sx={{'.MuiOutlinedInput-notchedOutline': {border: '1px solid #B5D4F9'}}}
                name={'email'} value={email || ''} onChange={handleChangeEmail} error={emailErr} type={'email'} required
                placeholder={'E-mail'}/>
            </Box>
            <Box className={styles.inputWrapper}>
              <span className={styles.inputLabel}>Телефон</span>
              {/*/!*<TextInput*!/*/}
              {/*  // sx={{'.MuiOutlinedInput-notchedOutline': {border: '1px solid #B5D4F9'}}}*/}
              {/*  // name={'phone'} value={phone} onChange={handleChangePhone} error={phoneErr} type={"tel"}*/}
              {/*  // placeholder={'Телефон'}/>*/}
              <PhoneInput onChange={handleChangePhone} value={phone} error={phoneErr} name={'phone'} required
                          placeholder={'Введите телефон'} sx={{'.MuiOutlinedInput-notchedOutline': {border: '1px solid #B5D4F9'}}}/>
            </Box>
            <Box className={styles.inputWrapper}>
              <span className={styles.inputLabel}>Пароль</span>
              <TextInput
                sx={{'.MuiOutlinedInput-notchedOutline': {border: '1px solid #B5D4F9'}}}
                name={'pass'} value={pass} onChange={handleChangePass} error={passErr || (pass !== pass_repeat)}
                type={"password"} placeholder={'Пароль'}/>
            </Box>
            <Box className={styles.inputWrapper}>
              <span className={styles.inputLabel}>Повторите пароль</span>
              <TextInput
                sx={{'.MuiOutlinedInput-notchedOutline': {border: '1px solid #B5D4F9'}}}
                onPaste={handlePastePass_repeat}
                name={'pass_repeat'} value={pass_repeat} onChange={handleChangePass_repeat}
                error={pass_repeatErr || (pass !== pass_repeat)} type={"password"} placeholder={'Повторите пароль'}/>
            </Box>
          </Box>
          <Box className={styles.accept}>
            <FormControlLabel
              sx={{
                margin: 0,
                '.MuiFormControlLabel-label': {
                  fontSize: '16px',
                  fontWeight: 500,
                  letterSpacing: '0.02em',
                  color: '#96A8B8',
                },
              }}
              control={
                <CheckboxInput
                  className={clsx([check_err && `${styles.accept_err}`])}
                  name="remember"
                  checked={check}
                  onChange={handleChangeCheck}
                />
              }
              label={
                <span className={styles.grayRules}>
      Я согласен с{' '}
                  <div className={styles.blueRules} onClick={()=>rules(event)}>
         политикой обработки персональных данных
      </div>
    </span>
              }
            />
          </Box>
          <Box className={styles.submitBtn}>
            <ButtonCustom disabled={loading} onClick={handleSubmit}>
              {loading ? 'Подождите...' : 'Создать аккаунт'}
            </ButtonCustom>
          </Box>
        </Box>
      </Box>)
    }
  }


  return (
    <>
      {renderBlock()}
    </>
  );
};
